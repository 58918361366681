import { useQuery } from '@tanstack/react-query';

import { serviceApi } from '~/features';
import { ActiveCampaignProps } from '~/types/activeCampaign';

export const GET_ACTIVE_CAMPAIGNS_QUERY_KEY = 'GET_ACTIVE_CAMPAIGNS_QUERY';

async function getActiveCampaigns() {
  const response = await serviceApi.get<ActiveCampaignProps[]>(
    '/Campanha/ActiveCampaigns'
  );
  return response;
}

export function useGetActiveCampaigns() {
  return useQuery({
    queryKey: [GET_ACTIVE_CAMPAIGNS_QUERY_KEY],
    queryFn: getActiveCampaigns,
    select: data => data?.data || [],
  });
}

export type UseGetActiveCampaignsReturn = ReturnType<
  typeof useGetActiveCampaigns
>['data'];
