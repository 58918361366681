import {
  AnunciantesIcon,
  CampanhasIcon,
  DashboardIcon,
  GerenciamentoIcon,
  RelatoriosIcon,
} from '~/shared';
import { labelMenu, submenu } from '~/types/constants/labelMenu';

export const navList = [
  {
    label: labelMenu.dashboard,
    icon: <DashboardIcon />,
    href: '/dashboard',
  },
  {
    label: labelMenu.inventory,
    icon: <CampanhasIcon />,
    href: '/campanhas',
    submenu: true,
    subMenuItems: [
      {
        label: submenu.banners,
        href: '/campanhas',
        subMenu: true,
        subMenuItems: [
          {
            label: submenu.campaign,
            href: '/campanhas',
          },
          {
            label: submenu.actives,
            href: '/campanhas-ativas',
          },
        ],
      },
      {
        label: submenu.sponsored,
        href: '/patrocinados',
        submenu: false,
      },
    ],
  },

  {
    label: labelMenu.advertisers,
    icon: <AnunciantesIcon />,
    href: '/anunciantes',
  },
  {
    label: labelMenu.reports,
    icon: <RelatoriosIcon />,
    href: '/relatorios',
  },
  {
    label: labelMenu.users,
    icon: <GerenciamentoIcon />,
    href: '/gerenciamento',
  },
];
